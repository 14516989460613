
	frappe.templates['change_log'] = `{% for (var i=0, l=change_log.length; i < l; i++) {
    var app_info = change_log[i]; %}
	{% if (i > 0) { %} <hr> {% } %}
	<div class="text-left app-change-log">
		<h2 style="margin-top: 10px;">
            {{ app_info.title }}
			<small>{{ __("updated to {0}", [app_info.version]) }}</small>
		</h2>
		<div class="app-change-log-body">
		{% for (var x=0, y=app_info.change_log.length; x < y; x++) {
            var version_info = app_info.change_log[x];
            if(version_info) { %}
    			<p>{{ frappe.markdown(version_info[1]) }}</p>
		    {% }
        } %}
    	</div>
	</div>
{% } %}
`;
