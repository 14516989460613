
	frappe.templates['search'] = `<div class="search-results flex">
	<div class="col-md-3 col-sm-3 hidden-xs layout-side-section">
		<ul class="overlay-sidebar list-unstyled search-sidebar"></ul>
	</div>
	<div class="col-md-9 col-sm-9 layout-main-section results-area">

	</div>
</div>
`;
