
	frappe.templates['workspace_loading_skeleton'] = `<div class="workspace-skeleton">
	<div class="widget-group">
		<div class="widget-group-head skeleton-header">
			<div class="widget-group-title skeleton-card"></div>
		</div>
		<div class="widget-group-body grid-col-3">
			<div class="widget shortcut-widget-box skeleton-card"></div>
			<div class="widget shortcut-widget-box skeleton-card"></div>
			<div class="widget shortcut-widget-box skeleton-card"></div>
		</div>
	</div>
	<div class="widget-group skeleton-body">
		<div class="widget-group-head skeleton-header">
			<div class="widget-group-title skeleton-card"></div>
		</div>
		<div class="widget-group-body grid-col-3">
			<div class="widget links-widget-box skeleton-card"></div>
			<div class="widget links-widget-box skeleton-card"></div>
			<div class="widget links-widget-box skeleton-card"></div>
			<div class="widget links-widget-box skeleton-card"></div>
			<div class="widget links-widget-box skeleton-card"></div>
		</div>
	</div>
</div>
`;
